.adyen-checkout-error-panel {
  margin-bottom: 20px;
}
.adyen-checkout-error-panel .adyen-checkout-error-panel__wrapper {
  font-size: 0.75em;
}

.adyen-checkout-error-panel--sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}