.adyen-checkout__checkbox {
  display: block;
}
.adyen-checkout__checkbox__label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  display: inline-block;
  line-height: 19px;
  color: #00112c;
  font-size: 0.81em;
  font-weight: normal;
  user-select: none;
}
[dir=rtl] .adyen-checkout__checkbox__label {
  padding-left: 0;
  padding-right: 24px;
}

.adyen-checkout__checkbox__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  /* Check */
  /* Box */
}
.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::before {
  opacity: 1;
}
.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
  border: 1px solid #0075ff;
  background-color: #0075ff;
}
.adyen-checkout__checkbox__input:checked:hover + .adyen-checkout__checkbox__label::after {
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
  border-color: #0075ff;
}
.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label::after {
  border: 1px solid #0075ff;
  box-shadow: 0 0 0 2px #99c2ff;
}
.adyen-checkout__checkbox__input:hover:not(:focus) + .adyen-checkout__checkbox__label::after {
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
}
.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::before {
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  border-radius: 0 2px 1px;
  content: "";
  height: 11px;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
  transition: opacity 0.2s ease-out;
  width: 6px;
  z-index: 1;
}
[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::before {
  left: auto;
  right: 8px;
}
.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  z-index: 0;
  transition: background 0.15s ease-out, border 0.05s ease-out, box-shadow 0.1s ease-out;
}
[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::after {
  left: auto;
  right: 0;
}

.adyen-checkout__field--consentCheckbox {
  background: #e6e9eb;
  border: 1px solid #e6e9eb;
  border-radius: 6px;
  padding: 14px 14px 13px;
}
[dir=rtl] .adyen-checkout__field--consentCheckbox {
  padding: 14px 14px 13px;
}
.adyen-checkout__field--consentCheckbox.adyen-checkout__field--error {
  border-color: #c12424;
}
.adyen-checkout__field--consentCheckbox .adyen-checkout-input__inline-validation {
  right: -5px;
  top: 10px;
}