.adyen-checkout__adyen-giving .adyen-checkout__status__icon {
  display: block;
  margin: 56px auto 32px;
}
.adyen-checkout__adyen-giving .adyen-checkout__status__text {
  color: #00112c;
  margin-bottom: 56px;
  text-align: center;
}

.adyen-checkout__campaign {
  border-radius: 6px;
  background: #00112c;
  height: 227px;
  overflow: hidden;
  position: relative;
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
  text-decoration: underline;
}

.adyen-checkout__campaign-container {
  height: 100%;
}

.adyen-checkout__campaign-logo {
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  display: block;
  height: 48px;
  margin-bottom: 16px;
  overflow: hidden;
  width: 48px;
}

.adyen-checkout__campaign-background-image {
  background-color: #00112c;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image::before {
  background: inherit;
  content: "";
  height: 100%;
  position: absolute;
  transition: transform 0.6s ease-out;
  width: 100%;
}
.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:hover::before {
  transform: scale(1.1);
}
.adyen-checkout__campaign-link .adyen-checkout__campaign-content {
  pointer-events: none;
}

.adyen-checkout__campaign-content {
  bottom: 0;
  padding: 16px;
  position: absolute;
  z-index: 2;
}

.adyen-checkout__campaign-title,
.adyen-checkout__campaign-description {
  color: #fff;
  font-weight: normal;
  margin: 0;
}

.adyen-checkout__campaign-title {
  font-size: 1em;
  margin-bottom: 8px;
}

.adyen-checkout__campaign-description {
  font-size: 0.81em;
  line-height: 19px;
}

.adyen-checkout__adyen-giving-actions {
  margin-top: 16px;
}

.adyen-checkout__button.adyen-checkout__button--donate {
  margin: 16px auto 8px;
}
.adyen-checkout__button.adyen-checkout__button--decline {
  display: block;
  margin: auto;
  width: initial;
}